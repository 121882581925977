/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import Helmet from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

function SEO({ description, lang, meta, title }) {
  const { site } = useStaticQuery(
    graphql`
      query SeoSiteQuery {
        site {
          siteMetadata {
            title
            description
          }
        }
      }
    `
  )

  const metaDescription = description || site.siteMetadata.description

  return (
    <Helmet
      htmlAttributes={{ lang: `sv-SE` }}
      title={title}
      defaultTitle={`${site.siteMetadata.title} - ${site.siteMetadata.description}`}
      titleTemplate={`%s | ${site.siteMetadata.title}`}
      meta={[
        {
          name: `robots`,
          content: `noindex`,
        },
        {
          property: `og:url`,
          content: 'https://static.hemneo.se',
        },
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:image`,
          content: 'https://static.hemneo.se/og-hemneo-static1.jpg',
        },
        {
          property: `og:image`,
          content: 'https://static.hemneo.se/og-hemneo-static2.jpg',
        },
        {
          property: `og:image`,
          content: 'https://static.hemneo.se/og-hemneo-static3.jpg',
        },
        {
          property: `og:image`,
          content: 'https://static.hemneo.se/og-hemneo-static4.jpg',
        },
        {
          property: `og:image`,
          content: 'https://static.hemneo.se/og-hemneo-static5.jpg',
        },
        {
          property: `og:type`,
          content: `website`,
        },
      ].concat(meta)}
    />
  )
}

SEO.defaultProps = {
  lang: `sv-SE`,
  meta: [],
  description: ``,
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
}

export default SEO
