/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql, Link } from "gatsby"
import { Global } from "@emotion/core"
import styled from "@emotion/styled"

import Header from "./header"
import layoutReset from "./_layoutReset"
import hemneoColors from '../utils/hemneoColors';

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  const LayoutContainer = styled.main`
    margin: 0 auto;
    max-width: 960px;
    padding: 0 8px 8px;
  `

  const Footer = styled.footer`
    font-family: 'Roboto', sans-serif;
    font-size: 14px;
    margin-top: 16px;
    padding: 8px 0;
    display: block;
    text-align: center;
    background: ${hemneoColors.B4};
    color: ${hemneoColors.B2};

    @media (min-width: 800px) {
      padding-bottom: 128px;
    }
  `

  const FooterLink = styled(Link)`
    color: ${hemneoColors.B2};
  `

  return (
    <>
      <Global styles={layoutReset} />
      <Header siteTitle={data.site.siteMetadata.title} />
      <LayoutContainer>
        {children}
      </LayoutContainer>
      <Footer>
          <FooterLink to="/">Hemneo Static</FooterLink>.
          All data med 💚 från Hemnet API.
      </Footer>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
