import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import styled from "@emotion/styled"

import HemneoLogoSvg from './hemneo-logo.svg';
import hemneoColors from '../utils/hemneoColors';

const HeaderContainer = styled.header`
  margin-bottom: 8px;
  padding: 0;
  border-bottom: 1px solid #ccc;
`

const HeaderContainerInner = styled.div`
  max-width: 960px;
  margin: 0 auto;
  display: flex;
  align-items: top;
  justify-content: space-between;

  @media (min-width: 800px) {
    justify-content: flex-start;
  }
`

const HeaderLogoLink = styled(Link)`
  display: flex;
  margin: 0;
  padding: 16px 16px 16px 8px;
  color: #000;
  text-decoration: none;
  font-weight: bold;
`

const HeaderLogoImg = styled.img`
  width: 141px;
  height: 27px;
  margin: 0;
`

const HeaderLogoTagline = styled.div`
  font-family: 'Roboto', sans-serif;
  font-size: 8px;
  color: #777;
  text-transform: uppercase;
`

const HeaderMenu = styled.div`
  display: flex;
`

const HeaderMenuLink = styled(Link)`
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  font-weight: bold;
  list-style: none;
  display: flex;
  padding: 16px;
  border-left: 1px solid #ccc;
  text-decoration: none;
  color: ${hemneoColors.B3};
  justify-content: center;
  align-items: center;

  &:hover {
    color: ${hemneoColors.B1};
    background: ${hemneoColors.B6};
  }

  &:first-of-type {
    margin-left: 0;
  }

  &:last-of-type {
    border-right: 1px solid #ccc;
  }

  &:nth-child(n+2) {
    display: none;
  }

  @media (min-width: 800px) {
    &:nth-child(n+1) {
      display: flex;
    }

    &:last-child {
      border-right: 1px solid #ccc;
    }
  }
`

const Header = ({ siteTitle }) => (
  <HeaderContainer>
    <HeaderContainerInner>
      <HeaderLogoLink to="/">
        <HeaderLogoImg src={HemneoLogoSvg} alt="Hemneo" />
        <HeaderLogoTagline>Static</HeaderLogoTagline>
      </HeaderLogoLink>
      <HeaderMenu>
        <HeaderMenuLink to="/bostader">Sök</HeaderMenuLink>
        <HeaderMenuLink to="/salja-bostad">Sälja bostad</HeaderMenuLink>
        <HeaderMenuLink to="/artiklar">Artiklar</HeaderMenuLink>
        <HeaderMenuLink to="/bygga-hus">Bygga hus</HeaderMenuLink>
        <HeaderMenuLink to="/utland">Utland</HeaderMenuLink>
      </HeaderMenu>
    </HeaderContainerInner>
  </HeaderContainer>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
